import { withStyle } from 'styletron-react'
import { styled } from 'mz-ui-kit'

export const Table = styled('table', ({ $palette }) => ({
  width: '100%',
  border: `1px solid ${$palette('GRAY')}`,
  borderCollapse: 'collapse',
  borderBottom: 'none',
}))

export const Body = styled('tbody', {
  fontSize: '12px',
})

const getRowColor = (alert, vip, $palette) => {
  if (alert) {
    return `${$palette('RED').lighten(40)}`
  }

  if (vip) {
    return '#FEECA0'
  }

  return 'none'
}

export const Row = styled('tr', ({ $alert, $clickable, $palette, $vip }) => ({
  cursor: $clickable ? 'pointer' : 'inherit',
  borderCollapse: 'collapse',
  borderBottom: `1px solid ${$palette('GRAY')}`,
  background: getRowColor($alert, $vip, $palette),
  ':hover': {
    background: $clickable ? `${$palette('GRAY')} !important` : 'none',
  },
}))

export const Headers = withStyle(Row, ({ $palette }) => ({
  fontWeight: 'bold',
  color: `${$palette('TEXT')}`,
}))

export const Cell = styled('td', ({ $palette }) => ({
  padding: '5px',
  borderCollapse: 'collapse',
  borderBottom: `1px solid ${$palette('GRAY')}`,
}))

export const Action = styled('div', () => ({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}))

export const Pointer = styled('div', () => ({
  cursor: 'pointer',
}))
