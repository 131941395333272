import React from 'react'
import { useDispatch } from 'react-redux'
import Passenger from 'mz-icons/passenger'
import { FormattedMessage } from 'mz-intl'

import NoResults from 'app/components/commons/NoResults'
import TripDetails from 'app/components/trips/TripDetails'
import { Table, Body, Headers, Row, Cell } from 'app/components/table/elements'
import {
  getPickupTime,
  getTripDate,
  isUrgent,
  hasUrgentTrip,
  getVehicleType,
  getTripType,
} from 'app/utils/trips'

import { getTrips, setTripStatus } from '../../../actions/trips'
import UrgentTripsAlert from './UrgentTripsAlert'
import {
  ConfirmationNumber,
  Tooltip,
  TooltipTitle,
  VipBlock,
  VipBlockContainer,
} from './elements'
import messages from './messages.intl'
import { TripStatus } from './TripStatus'
import { useHistory, useParams } from 'react-router-dom'
import { TrackingLink } from './TrackingLink'

export const TripsList = ({ trips, tripStatuses }) => {
  const dispatch = useDispatch()
  const { hash } = useParams()
  const history = useHistory()
  const showDetails = Boolean(hash)

  const openDetails = (newHash) => () => {
    history.push(`/trips/${newHash}`)
  }

  const closeDetails = () => {
    history.push('/trips')
  }

  const changeStatus = async (
    currentStatus,
    selectedStatus,
    hashId,
    providerName
  ) => {
    await dispatch(
      setTripStatus(hashId, currentStatus, selectedStatus, providerName)
    )
    dispatch(getTrips())
  }

  const getTripStatus = (hashedId) => {
    const status = tripStatuses.find(
      (tripStatus) => tripStatus.tracking_id === hashedId
    )

    return (
      status || { formattedStatus: { name: 'Not Started', color: 'DARKGRAY' } }
    )
  }

  if (!trips.length) return <NoResults />

  return (
    <article>
      {showDetails && (
        <TripDetails
          hash={hash}
          showed={showDetails}
          close={closeDetails}
          onChangeTrip={openDetails}
        />
      )}
      {hasUrgentTrip(trips) && <UrgentTripsAlert />}
      <Table>
        <Body>
          <Headers>
            <Cell> </Cell>
            <Cell> Pickup Time </Cell>
            <Cell> Date </Cell>
            <Cell>Confirmation No</Cell>
            <Cell>
              <Passenger />
            </Cell>
            <Cell> Type </Cell>
            <Cell> VehicleType </Cell>
            <Cell> Start Address </Cell>
            <Cell> End Address </Cell>
            <Cell> Flight Number </Cell>
            <Cell> Status </Cell>
            <Cell> Driver </Cell>
            <Cell> Vehicle </Cell>
            <Cell> Tracking </Cell>
          </Headers>
          {trips.map((trip, index) => {
            const {
              rebooked_reservation_user_id: rebooked,
              original_reservation_user_id: original,
            } = trip

            const { formattedStatus } = getTripStatus(trip.hashed_id) || {
              formattedStatus: { status: '', color: '' },
            }

            return (
              <Row
                key={index}
                $clickable
                $alert={isUrgent(trip)}
                $vip={trip.is_vip}
                onClick={openDetails(trip.hashed_id)}
              >
                <Cell>
                  {' '}
                  {trip.is_vip && (
                    <VipBlockContainer>
                      <VipBlock>VIP</VipBlock>
                      <Tooltip className="tooltip">
                        <TooltipTitle>VIP ride</TooltipTitle>
                        Please provide exceptional service for this ride. Make
                        sure to assign a driver long before the ride and track
                        every step through the Mobile Driver App.
                      </Tooltip>
                    </VipBlockContainer>
                  )}
                </Cell>
                <Cell> {`${getPickupTime(trip)}`} </Cell>
                <Cell> {`${getTripDate(trip)}`} </Cell>
                <Cell>
                  {trip.user_external_id}
                  {original && (
                    <ConfirmationNumber $alert={isUrgent(trip)}>
                      <FormattedMessage
                        message={messages.OLD_CONFIRMATION_NUMBER}
                        values={{
                          number: original,
                        }}
                      />
                    </ConfirmationNumber>
                  )}
                  {rebooked && (
                    <ConfirmationNumber $alert={isUrgent(trip)}>
                      <FormattedMessage
                        message={messages.NEW_CONFIRMATION_NUMBER}
                        values={{
                          number: rebooked,
                        }}
                      />
                    </ConfirmationNumber>
                  )}
                </Cell>
                <Cell> {trip.num_passengers} </Cell>
                <Cell> {getTripType(trip)} </Cell>
                <Cell> {`${getVehicleType(trip)}`} </Cell>
                <Cell>
                  {' '}
                  {trip.start_location && trip.start_location.address}{' '}
                </Cell>
                <Cell> {trip.end_location && trip.end_location.address} </Cell>
                <Cell>
                  {trip.flight_info?.flight_code
                    ? trip.flight_info?.flight_code
                    : 'Not available'}
                </Cell>
                <TripStatus
                  status={formattedStatus}
                  disableChange={!trip.driver}
                  isLoading={trip.isLoading}
                  onChange={(newStatus) =>
                    changeStatus(
                      trip.status,
                      newStatus,
                      trip.hashed_id,
                      trip.provider_name
                    )
                  }
                />
                <Cell>
                  {' '}
                  {trip.driver
                    ? `${trip.driver.first_name} ${trip.driver.last_name}`
                    : ''}{' '}
                </Cell>
                <Cell>
                  {trip.vehicle
                    ? `${trip.vehicle.model} - ${
                        trip.vehicle.plate || 'Unknow plate'
                      }`
                    : ''}
                </Cell>
                {trip.driver_tracking_link ? (
                  <TrackingLink trackingLink={trip.driver_tracking_link} />
                ) : (
                  <Cell />
                )}
              </Row>
            )
          })}
        </Body>
      </Table>
    </article>
  )
}
