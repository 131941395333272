import React, { useEffect, useRef, useState } from 'react'

import { Link } from './elements'
import copy from 'img/copy.svg'
import tick from 'img/tick.svg'

export const TrackingLink = ({ trackingLink }) => {
  const [copied, setCopied] = useState(false)

  const timeoutRef = useRef(null)

  const handleCopyTrackingLink = (e) => {
    e.preventDefault()
    e.stopPropagation()

    navigator.clipboard.writeText(trackingLink)

    setCopied(true)

    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  return (
    <Link onClick={handleCopyTrackingLink} $link>
      {copied ? (
        <>
          <img src={tick} alt="Copy" /> {`Link copied!`}
        </>
      ) : (
        <>
          <img src={copy} alt="Copy" /> {`Copy Link for Driver`}
        </>
      )}
    </Link>
  )
}
